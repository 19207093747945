import { Pipe, PipeTransform } from '@angular/core';
import { setupConfig } from '@ionic/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {

    transform(value: Array<any>, args: any[]): any {
        if (args === undefined) {
            return;
        }
        const sortField: any = args[0];
        const sortDirection = args[1];
        let multiplier = 1;

        if (sortDirection === 'desc') {
            multiplier = -1;
        }

        if (sortField) {
            value.sort((a: any, b: any) => {
                let firstValue = a[sortField];
                let secondValue = b[sortField];
                if (firstValue.toLowerCase() < secondValue.toLowerCase()) {
                    return -1 * multiplier;
                } else if (firstValue.toLowerCase() > secondValue.toLowerCase()) {
                    return 1 * multiplier;
                } else {
                    return 0;
                }
            }

            );
        }


        return value;
    }
}