import { Status } from "./Status.model";

import { Document } from "./document.model";

type ParseResult<T> =
| { parsed: T; hasError: false; error?: undefined }
| { parsed?: undefined; hasError: true; error?: unknown }

export class Product {
  public tokenid: string;
  public container: container;
  public drug:Drug;
  public proprieta_storico: Storico[];

  constructor() {
    this.proprieta_storico = []; 
  }
}

export class ProductList {
  public tokenid: string;
  public prodotto: string;
  public lotto: string;
  public scandenza: string;
  public timestamp: string;
  public b: string;
}


export class container { 
  public serialid:string;
  public name:string;
  public productcode:string;
  public glasstype:string;
  public productionfacility:string;
  public productionline:string;
  public productionmould:string;
  public productiontimestamp:string;
  public palletnumber:string;
  public batchnumber:string;
  public link:string;
  public clientname:string;
  public resortinglevel:string;
};

export class Drug {
  public serialid :string;
  public productname:string;
  public productionfacility:string;
  public productionline:string;
  public productcip:string;
  public clientcode:string;
  public companyname:string;
  public stateidauthorization:string;
  public batchnumber:string;
  public expirationdate:string;
  public link1:string;
  public link2:string;
}

export class Storico {
  public stato: string;
  public timestamp: Date;
  public address: string;
}

export class Farmaceutica {
  public nome: string; 
  public address: string;
  public username: string;
  constructor(nome:string,address:string,username:string) {
    this.nome = nome; 
    this.address = address; 
    this.username=username;
  }
}
