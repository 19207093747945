import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from "./services/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/onboarding-tour',
    pathMatch: 'full'
  },
  //{ path: '**', loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule) },
  {
    path: 'bugiardino/:tokenid',
    loadChildren: () => import('./bugiardino/bugiardino.module').then(m => m.BugiardinoPageModule),

  },
  {
    path: 'onboarding-tour',
    loadChildren: () => import('./boarding-tour/boarding-tour.module').then(m => m.BoardingTourPageModule),

  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),

  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'production-phase',
    loadChildren: () => import('./production-phase/production-phase.module').then(m => m.ProductionPhasePageModule)
  },
  {
    path: 'breeding',
    loadChildren: () => import('./breeding/breeding.module').then(m => m.BreedingPageModule)
  },
  {
    path: 'productioncollection',
    loadChildren: () => import('./productioncollection/productioncollection.module').then(m => m.productioncollectionPageModule)
  },
  {
    path: 'filter/:isHidden',
    loadChildren: () => import('./filter/filter.module').then(m => m.FilterPageModule)
  },
  {
    path: 'bottled-barrel',
    loadChildren: () => import('./bottled-barrel/bottled-barrel.module').then(m => m.BottledBarrelPageModule)
  },
  {
    path: 'laboratory-analysis-phase',
    loadChildren: () => import('./laboratory-analysis-phase/laboratory-analysis-phase.module').then(m => m.LaboratoryAnalysisPhasePageModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./analysis/analysis.module').then(m => m.AnalysisPageModule)
  },
  {
    path: 'bottling',
    loadChildren: () => import('./bottling/bottling.module').then(m => m.BottlingPageModule)
  },
  {
    path: 'charge-kegs',
    loadChildren: () => import('./charge-kegs/charge-kegs.module').then(m => m.ChargeKegsPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'add-burr',
    loadChildren: () => import('./add-burr/add-burr.module').then(m => m.AddBurrPageModule)

  },
  {
    path: 'scanner',
    loadChildren: () => import('./scanner/scanner.module').then(m => m.ScannerPageModule)

  },
  {
    path: 'view/:id',
    loadChildren: () => import('./scanner/scanner.module').then(m => m.ScannerPageModule)

  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
  },
  {
    path: 'edit-user/:id',
    loadChildren: () => import('./edit-user/edit-user.module').then(m => m.EditUserPageModule)
  },
  {
    path: 'finished-burrs',
    loadChildren: () => import('./finished-burrs/finished-burrs.module').then(m => m.FinishedBurrsPageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'zilliqa-blockchain/:id',
    loadChildren: () => import('./zilliqa-blockchain/zilliqa-blockchain.module').then(m => m.ZilliqaBlockchainPageModule)
  },
  {
    path: 'pre-sale',
    loadChildren: () => import('./pre-sale/pre-sale.module').then(m => m.PreSalePageModule)
  },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },







];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
