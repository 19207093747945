import { Role } from "./role.model";

export class User {
    public _id: string;
    public name: string;
    public surname: string;
    public email: string;
    public username: string;
    public password: string;
    public roleId: number;
}