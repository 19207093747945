import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { productioncollectionService } from 'src/app/productioncollection/productioncollection.service';
import { AlertService } from 'src/app/services/alert.service';
import { RoleToEnum } from 'src/assets/enums/roleEnum';
import { StatusToPhase } from 'src/assets/enums/statusEnum';
import { environment } from 'src/environments/environment';
import { Drug, Farmaceutica, Product, Storico } from 'src/models/Product.model';
import { Status } from 'src/models/Status.model';
import { User } from 'src/models/user.model';
import { Storage } from '@ionic/storage';
import { SmartContractService } from 'src/app/services/smartcontract.service';
import { ProductBlockchain } from 'src/models/ProductBlockchain.model';
import { Plugins, CameraResultType, CameraSource, Capacitor } from '@capacitor/core';

const { Camera } = Plugins;

@Component({
  selector: 'app-data-component',
  templateUrl: './data-component.component.html',
  styleUrls: ['./data-component.component.scss'],
})
export class DataComponentComponent implements OnInit {
  @ViewChild('filePicker', { static: false }) filePickerRef: ElementRef<HTMLInputElement>;
  @Input('isFilter') isFilter: boolean;
  @Input('isBottled') isBottled: boolean;
  @Input('isLoaded') isLoaded: boolean;
  @Input('isAnalise') isAnalise: boolean;
  @Input('isBottling') isBottling: boolean;
  @Input('isPreSale') isPreSale: boolean;
  @Input('isCustomer') isCustomer: boolean
  @Input('prodotto') prodotto: Product;
  @Input('Id') Id: string;
  @Input('isHidden') isHidden: boolean;
  @Output() newItemEvent = new EventEmitter<boolean>();
  isDesktop: boolean;
  private _jsonURL = 'assets/bugiardino.json';
  tranactionLink = '';
  latitude: number;
  latitudeNow: number;
  prodotto2: Product;
  drug2:Drug;
  longitude: number;
  longitudeNow: number;
  documentsBlockChain = [];
  documentModel: any;
  hideWeight: boolean;
  selectedFiles = [];
  users = [];
  weight: number;
  nrBottles: number;
  roleId = RoleToEnum.Lab_TAG;
  user: User;
  nrOfFiles: string;
  status: Status;
  spinner: any;
  nrKegs: number;
  batch: string;
  bavaStatusId: string;
  statusEnum;
  ProductBlockchain: ProductBlockchain;
  locationURL: string;
  prodottoCopy: any;
  addresscustomer: any;
  listaFarmaceutica: Array<Farmaceutica>;
  listaFarmacia: Array<Farmaceutica>;
  listaCustomer: Array<Farmaceutica>;
  farmaceutica: Farmaceutica;
  farmacista: Farmaceutica;
  pazziente: Farmaceutica;
  blockChainCode: any;
  timestamp: any
  fileToUpload: any;
  isFieldValid: boolean;
  value: any;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private loadingController: LoadingController,
    private alertService: AlertService,
    private alertController: AlertController,
    private httpClient: HttpClient,
    private platform: Platform,
    private productioncollectionService: productioncollectionService,
    public smartContractService: SmartContractService,
    public storage: Storage) {

    this.user = new User();
    this.hideWeight = true;
    // [['Farmaceutica 1','address'], ['Farmaceutica 2','address'], ['Farmaceutica 3','address']];
    this.listaFarmaceutica = new Array<Farmaceutica>();
    this.listaFarmaceutica.push(new Farmaceutica('Bayer', 'sXvSmq8fHLEmrU1WrysMtVxcTCdiSzcHwa', 'farmaceutica'));
    this.listaFarmaceutica.push(new Farmaceutica('Farmaceutica 2', 'sXvSmq8fHLEmrU1WrysMtVxcTCdiSzcHwa', 'farmaceutica'));
    this.listaFarmacia = new Array<Farmaceutica>();
    this.listaFarmacia.push(new Farmaceutica('Farmacia 1', 'sg9HVko6EgHZWTAM6Rqw5E2oQF79HCB4pM', 'farmacia'));
    this.listaFarmacia.push(new Farmaceutica('Ospedale 1', 'sg9HVko6EgHZWTAM6Rqw5E2oQF79HCB4pM', 'farmacia'));
    this.listaCustomer = new Array<Farmaceutica>();
    this.listaCustomer.push(new Farmaceutica('Paziente 1', 'sXtBGEmRUj2kCmw9Yk3cuuNZi5cU6Q6wPx', 'customer'));
    this.listaCustomer.push(new Farmaceutica('Paziente 2', 'sXtBGEmRUj2kCmw9Yk3cuuNZi5cU6Q6wPx', 'customer'));
    this.isFieldValid = true;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  async ngOnInit() {
    if (this.isLoaded)
      this._jsonURL = 'assets/bugiardino2.json';
    if (this.isAnalise)
      this._jsonURL = 'assets/bugiardino2.json';
    if (this.isPreSale)
      this._jsonURL = 'assets/bugiardino3.json';
    if (this.isBottled)
      this._jsonURL = 'assets/bugiardino4.json';
  

    this.spinner = await this.loadingController.create({
      spinner: 'circles',
      message: "<div class='custom-spinner-container'>  <div class='custom-spinner-box'>Caricamento...</div></div>"
     
    });
    await this.spinner.present();
    this.isHidden = false;
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      this.isDesktop = true;
      await this.storage.set('isDesktop', true);
    } else {
      this.isDesktop = false;
      await this.storage.set('isDesktop', this.isDesktop);
    }
    this.getStatus();
    this.storage.get('prodottoId').then((data: any) => {
      this.Id = data;
      this.getProdotto(data);
    });

  }

  async ngOnChanges() {
    await this.storage.set('isHidden', false);
  }

  async ionViewWillEnter() {
    await this.storage.set('isDesktop', true);
    this.isDesktop = await this.storage.get('isDesktop');
    this.storage.get('prodottoId').then((data: any) => {
      this.Id = data;
      this.getProdotto(data);
    });
  }

  getStatus() {
    if (this.isFilter === true) {
      this.hideWeight = false;
      this.statusEnum = StatusToPhase.Fil_TAG;
    } else if (this.isBottled === true) {
      this.hideWeight = false;
      this.statusEnum = StatusToPhase.Imbo_TAG;
    } else if (this.isLoaded === true) {
      this.hideWeight = false;
      this.statusEnum = StatusToPhase.Carico_TAG;
    } else if (this.isAnalise === true) {
      this.hideWeight = true;
      this.statusEnum = StatusToPhase.Analys_TAG;
    } else if (this.isBottling === true) {
      this.statusEnum = StatusToPhase.ImboInFusto_TAG;
    } else if (this.isPreSale === true) {
      this.statusEnum = StatusToPhase.PreVendita_TAG;
    }

  }

  calculatePositionCoordinates(dataGeolocation) {
    var index = dataGeolocation.toString().indexOf("; ")
    this.latitude = dataGeolocation.substring(0, index - 2);
    this.longitude = dataGeolocation.substring(index + 2);
    this.locationURL = environment.googleMapUrl + this.latitude + ',' + this.longitude;
  }

  async getProdotto(Id: string) {
    this.spinner.dismiss();
    var datastring = await this.smartContractService.getExtraInfo(Id);

    await this.httpClient.get('assets/bugiardino2.json').subscribe(async (data: any) => {
      let ggg1 = data as Product;
      this.prodotto2 = ggg1; 
      this.drug2=ggg1.drug;
      this.prodotto = ggg1;
      this.prodotto.drug =null;
      this.prodotto.tokenid = Id;
      this.prodotto2.tokenid = Id;

    if (datastring) {
      try {
        var data1 = JSON.parse(datastring);
        let ggg = data1 as Product;
        this.prodotto = ggg;        
        if (this.isFilter === true)
              this.prodotto.drug =null;
         
        await this.storage.set("prodotto", ggg);     
        
      } catch (error) {       
  
      }   
      if (Id != null && Id != undefined)
      {
        this.prodotto.tokenid = Id;
        this.prodotto2.tokenid = Id;
      }
    }
    

  });
    
  
  }

  openBavaLoc() {
    window.open(this.locationURL, '_blank');
  }

  getTitle() {
    if (this.isFilter === true)
      return this.translate.instant('COLLECTION_DATA');
    else if (this.isBottled === true)
      return this.translate.instant('FILTERING_DATA');
    else if (this.isLoaded === true)
      return this.translate.instant('IMBO_DATA');
    else if (this.isAnalise === true)
      return this.translate.instant('CARICO_FUSTO');
    else if (this.isBottling === true)
      return this.translate.instant('ANALISI_DATA');
  }

  getFilteredRoles(id: string) {
    return this.httpClient.get(environment.baseApiUrl + 'user/filterUser/' + id);
  }

  openLinkfunct() {
    window.open(this.tranactionLink, '_blank');
  }

 async goBack() {
    this.isHidden = true;
    await this.storage.remove('prodottoId');
    this.newItemEvent.emit(this.isHidden);
    await this.storage.remove('isHidden');
  }

  async logForm() {
    this.spinner = await this.loadingController.create({
      spinner: 'circles',
      message: "<div class='custom-spinner-container'><a target='_blank' href='https://testnet.otichain.net/tx/{{this.smartContractService.address}}}'>Explorer</a> </div>"
     
    });
    await this.spinner.present();
    window.addEventListener("keyup", disableF5);
    window.addEventListener("keydown", disableF5);

    function disableF5(e) {
      if ((e.which || e.keyCode) == 116) e.preventDefault();
    };

    history.pushState(window.location.toString(), null, window.location.toString());
    window.onpopstate = function () {
      history.go(1);
    };

    /*  if (this.isFilter === true) {
          if (!this.fieldIsNotEmpty(this.weight)) {
            this.dismissSpinnerAndShowWarning(this.translate.instant('FILL_FIELDS'))
            return;
          }
        }
        else if (this.isBottled === true) {
          this.isFieldValid = true;
          await this.checkIfFilledBottle();
          if (!this.isFieldValid) {
            this.OpenWarning(this.translate.instant('FILL_FIELDS'))
            return;
          }
        }
        else if (this.isLoaded === true) {
          if (!this.fieldIsNotEmpty(this.nrKegs)) {
            this.dismissSpinnerAndShowWarning(this.translate.instant('FILL_FIELDS'))
            return;
          }
    
        }
        else if (this.isPreSale === true) {
    
          
        }
        else if (this.isBottling === true) {
          if (!this.fieldIsNotEmpty(this.nrBottles)) {
            this.dismissSpinnerAndShowWarning(this.translate.instant('FILL_FIELDS'))
            return;
          }
        }*/
    try {
      /*  await Geolocation.getCurrentPosition().then((resp) => {
          this.latitudeNow = resp.coords.latitude;
          this.longitudeNow = resp.coords.longitude;
        });  */
      if (this.isBottled === true && this.isLoaded===true)
        this.prodotto.drug=this.drug2;      
      this.update(this.prodotto);
    } catch (error) {
      this.alertService.openWarning(this.translate.instant("GPS_LOCATION"));
      this.spinner.dismiss();
      return;
    }
  }

  handleNavigation() {
    if (this.isFilter === true) {
      this.router.navigate(['/laboratory-analysis-phase/bottling']);
    } else if (this.isBottled === true) {
      this.router.navigate(['/dashboard']);
    } else if (this.isLoaded === true) {
      this.router.navigate(['/laboratory-analysis-phase/analysis']);
    } else if (this.isAnalise === true) {
      this.router.navigate(['/laboratory-analysis-phase/bottling']);
    } else if (this.isBottling === true) {
      this.router.navigate(['/production-phase/pre-sale']);
    } else if (this.isPreSale === true) {
      this.router.navigate(['/dashboard']);
    }
  }

  async prepareForUpdate(prodotto: Product) {
    var storico = new Storico();
    prodotto.proprieta_storico=new Array<Storico>();
    if (this.isFilter === true) {
      storico.stato = "INVIO FARMACEUTICA";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    }
    else if (this.isBottled === true) {
      storico.stato = "Invio Farmaceutica";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    } else if (this.isLoaded === true) {
      storico.stato = "Invio Farmaceutica";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    }
    else if (this.isAnalise === true) {
      storico.stato = "Invio Farmaceutica";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    } else if (this.isBottling === true) {
      storico.stato = "Invio Farmaceutica";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    } else if (this.isPreSale === true) {
      storico.stato = "Invio Farmaceutica";
      storico.timestamp = new Date();
      storico.address = "";
      prodotto.proprieta_storico.push(storico)
    }
    this.ProductBlockchain = {
      extraInfo: JSON.stringify(prodotto),
      tokenid: this.prodotto.tokenid
    }
  }


  async SendTo(prodotto: Product) 
  {
    let username="";
    let address="";
    if (this.farmaceutica)
    {
      username=this.farmaceutica.username;
      address=this.farmaceutica.address;
    }
    if (this.farmacista)
    {
      username=this.farmacista.username;
      address=this.farmacista.address;
    }
    if (this.pazziente)
    {
      username=this.pazziente.username;
      address=this.pazziente.address;
    }
    if (this.isCustomer)
    {
      username="maker13752maker";
      address="m4k3r13752m4ke8";
    }
     
    
    await this.smartContractService.sendToken(prodotto.tokenid, username,address, "distributor");
     
  }

  async update(prodotto: Product) {
    try {
  
      await this.prepareForUpdate(prodotto);
      this.spinner.dismiss();
      if(!this.isCustomer)
      { 
        await this.smartContractService.setExtraInfo(prodotto.tokenid, JSON.stringify(prodotto));
      }
      if (this.farmacista || this.farmaceutica || this.pazziente || this.isCustomer) {
        await this.SendTo(prodotto);
      }
      this.spinner.dismiss();
      this.smartContractService.address="";
      this.goBack();

    }
    catch (error) {
      this.spinner.dismiss();
      this.alertService.openWarning(this.translate.instant('SOMETHING_WRONG_ERROR'));
    }
  }

  dismissSpinnerAndShowWarning(errorMsg: string) {
    this.spinner.dismiss();
    this.alertService.openWarning(errorMsg);
    this.weight = null;
    this.nrKegs = null;
    this.nrBottles = null;
    this.user = null

  }

  async dismissSpinnerAndShowWarningForEmptyFields(errorMsg: string, field?: any) {

    this.spinner.dismiss();

    if (field === "kegs")
      this.nrKegs = null;
    else if (field === "weight")
      this.weight = null;
  }

  showTransactionNotFinished() {
    this.spinner.dismiss();
    this.alertService.openWarning(this.translate.instant('ZILL_TRANSACTION_NOT_FINISHED'));
  }

  async getTransactionLink(txID: string) {
    let dataLink = await this.httpClient.get(environment.baseApiUrl + 'smart-contract/getTxLink/' + txID).toPromise();
    this.tranactionLink = dataLink.toString();
  }

  fieldIsNotEmpty(field: any) {
    return Boolean(field)
  }

  checkIfDocsFilled(docs: any) {
    if (docs.length === 0)
      return false;

    return true;
  }

  async checkIfFilledBottle() {
    if (this.weight === undefined || this.weight === null || this.weight == 0) {
      await this.dismissSpinnerAndShowWarningForEmptyFields(this.translate.instant('FILL_FIELDS'), "weight")
      this.isFieldValid = false;

    }
    if (this.nrKegs === undefined || this.nrKegs === null || this.nrKegs == 0) {
      await this.dismissSpinnerAndShowWarningForEmptyFields(this.translate.instant('FILL_FIELDS'), "kegs")
      this.isFieldValid = false;
    }
    if (this.user._id === undefined || this.user._id === null) {
      await this.dismissSpinnerAndShowWarningForEmptyFields(this.translate.instant('FILL_FIELDS'))
      this.isFieldValid = false;
    }
    return this.isFieldValid;
  }

  changeListener($event): void {
    let files = $event.target.files;
    for (let i = 0; i < $event.target.files.length; i++) {
      for (let j = 0; j < this.selectedFiles.length; j++) {
        if ($event.target.files[i].name === this.selectedFiles[j].name) {
          this.OpenWarning(this.translate.instant("SAME_FILE"));
          return;
        }
      }
      this.selectedFiles.push(files[i]);
      this.value = $event.target.files[i].name;
      this.fileToUpload = $event.target.files[i];
      const fileSize = this.fileToUpload.size;
      if (fileSize > 1048576) {
        this.OpenWarning(this.translate.instant("FILE_TOO_LARGE"));
        this.selectedFiles.pop();
        return;
      }
    }
  }

  async saveDocument(file: any) {
    for (let i = 0; i < file.length; i++) {
      const document = {
        name: file[i].name,
        type: file[i].type,
        createdDate: new Date(),
        extension: file[i].name.substring(file[i].name.lastIndexOf('.') + 1),
        hashcode: ''
      };

      const formData = new FormData();
      formData.append('file', file[i]);
      formData.append('createDocumentDTO', JSON.stringify(document));
      var url = environment.baseApiUrl + 'document/create';

      const rawResponse = await fetch(url, {
        method: 'POST',
        body: formData
      });

      const content = await rawResponse.json();
      if (content.doc !== undefined) {
        this.documentModel = await this.httpClient.get(environment.baseApiUrl + 'document/' + content.doc._id).toPromise();
        this.documentsBlockChain = this.documentsBlockChain.concat(this.documentModel.hashCode);

      }
      else {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          message: '<center ><img id="img " src = "./assets/danger-sign.png"  max-width="10%"  > <br><br>  <bold>' + this.translate.instant("FILE_UPLOAD_FAILURE") + ' </bold></center> '
        });
        await alert.present();
        alert.present();
        setTimeout(() => {
          alert.dismiss();
        }, 3000);

        if (await alert.onDidDismiss())
          return;
      }
    }
  }

  async OpenWarning(alertBody) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: '<center ><img id="img " src = "./assets/danger-sign.png"  max-width="10%"  > <br><br>  <bold>' + alertBody + ' </bold></center> '
    });
    await alert.present();
    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }

  @HostListener('keypress', ['$event'])
  onInput(event: any) {
    const pattern = /[0-9]/;
   /* let inputChar = String.fromCharCode(event.which ? event.which : event.keyCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
      return false;
    }*/
    return true;
  }

  async getPicture(type: string) {
    if (!Capacitor.isPluginAvailable('Camera') || (this.isDesktop && type === 'gallery')) {
      this.filePickerRef.nativeElement.click();
      return;
    }

    const image = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 50,
    });
    var fileName = this.generateName();
    let blob = await fetch(image.webPath).then(r => r.blob());
    var file = new File([blob], fileName, { type: 'image/png', lastModified: Date.now() });
    const fileSize = file.size;
    if (fileSize > 1048576) {
      this.OpenWarning(this.translate.instant("FILE_TOO_LARGE"));
      return;
    }
    this.selectedFiles.push(file);
  }

  generateName() {
    return "img" + Math.floor(1000 + Math.random() * 9000) + ".png";
  }

}
