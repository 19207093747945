import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { productioncollectionService } from 'src/app/productioncollection/productioncollection.service';
import { environment } from 'src/environments/environment';
import { Product} from 'src/models/Product.model';
import { DataComponentComponent } from '../data-component/data-component.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  @Input('isFilter') isFilter: boolean;
  @Input('isHidden') isHidden: boolean;
  @Output() newItemEvent = new EventEmitter<{id:string, hidden:boolean}>();
  bavas = [];
  bava: Product;
  id: string;
  constructor(private httpClient: HttpClient,
    public router: Router,
    private alertController: AlertController) { 
      this.id = "";
    }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.loadBavas();
  }

  loadBavas() {
    if(this.isFilter  === true ) {
      this.id = '60a61fc6123ae81cc8d44619';
    } else {
      this.id = '60a61fd2123ae81cc8d4461a';
    }
    
    this.httpClient.get(environment.baseApiUrl + 'bava/filterBavas/' + this.id).subscribe((data: any) => {
      this.bavas = data;
    })
  }

  openDetailPage(bava) {
    this.isHidden = true;
   this.id = bava._id;
   this.newItemEvent.emit({id:this.id, hidden:this.isHidden});
  }


  async OpenWarning(alertBody) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: '<center ><img id="img " src = "./assets/danger-sign.png"  max-width="10%"  > <br><br>  <bold>' + alertBody + ' </bold></center> '

    });
    await alert.present();
    alert.present();
    setTimeout(()=>{
        alert.dismiss();
    }, 3000);
  }




}
