import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(protected alertController: AlertController) { }

  async openSuccess(alertBody) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: '<center ><img id="img " src = "./assets/check.png"  max-width="10%"  > <br><br>  <bold>' + alertBody + ' </bold></center> '

    });
    await alert.present();
    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }

  async openWarning(alertBody) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      message: '<center ><img id="img " src = "./assets/danger-sign.png"  max-width="10%"  > <br><br>  <bold>' + alertBody + ' </bold></center> '
    });
    await alert.present();
    alert.present();
    setTimeout(() => {
      alert.dismiss();
    }, 3000);
  }
}
