import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataComponentComponent } from './data-component/data-component.component';
import { ListComponent } from './list/list.component';
import { PageComponent } from './page-component/page.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    NgxQRCodeModule
  ],
    declarations: [PageComponent, DataComponentComponent, ListComponent],
    exports: [PageComponent, DataComponentComponent, ListComponent], 
  })
  export class ComponentsModule {}