import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { RoleToEnum } from 'src/assets/enums/roleEnum';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];
  language: any;
  public iconPages = [];
  isHidden: boolean;
  isRoleAdm: boolean;
  isMobile: boolean = false;
  emailOfUser: string;
  user: string;

  constructor
    (private translate: TranslateService,
      private storage: Storage,
      public router: Router) {
    this.storage.create();
    var language = window.navigator.language;
    this.language = language.split('-');
    this.translate.setDefaultLang(this.language[0]);
    this.initializeApp();

  }

  checkLocal(){
    this.isRoleAdmChecking();
    this.getEmail();
  }
  initializeApp() {
    this.iconPages = [
      {
        icon: 'logo-facebook',
      },
      {
        icon: 'logo-twitter',
      },
      {
        icon: 'logo-pinterest',
      },
      {
        icon: 'logo-google',
      },
      {
        icon: 'logo-instagram',
      }
    ];
  }

  async getEmail() {
    //var user = await this.storage.get('username');
    this.user = await this.storage.get('username');
    this.isRoleAdmChecking();
    return this.user;
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  goToProducedBavas() {
    this.router.navigate(['/finished-burrs']);
  }

  // async changeInEnglish() {
  //   this.translate.setDefaultLang('en');
  //   this.translate.use('en');
  //   await this.storage.set('language', 'en');
  // }

  // async changeInItalian() {
  //   this.translate.setDefaultLang('it');
  //   this.translate.use('it');
  //   await this.storage.set('language', 'it');
  // }

  logout() {
    this.router.navigate(['/login']);
    this.storage.clear();
  }

  signup() {
    this.router.navigate(['/sign-up']);
  }
  goToUser() {
    this.router.navigate(['/user']);
  }



  async isRoleAdmChecking() {
    const helper = new JwtHelperService();
    var jwt = await this.storage.get('jwt');
    if (jwt) {
      const decodedToken = helper.decodeToken(await this.storage.get('jwt'));

      if (decodedToken.roleId === RoleToEnum.Adm_TAG)
        this.isRoleAdm = true;

      else this.isRoleAdm = false;


    }
    return;
  }

}
