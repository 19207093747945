import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { AppComponent } from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ComponentsModule } from './components/components.module';
import { SortPipeModule } from './pipes/sortPipe.module';
import { IonicStorageModule, Storage } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';  
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { AuthInterceptor } from './services/auth.interceptor';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, GoogleMapComponent],
  entryComponents: [],
  imports: [BrowserModule,NgxScannerQrcodeModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule ,
    ComponentsModule,
    BrowserModule, 
    ZXingScannerModule,
    IonicStorageModule.forRoot({
      name: 'myname',
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    SortPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ], 
  schemas: [ NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA ],
  providers: [   
    NativeGeocoder,  
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
 
})
export class AppModule {}