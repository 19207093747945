import { Component, OnInit, Input} from '@angular/core';
import { productioncollectionService } from 'src/app/productioncollection/productioncollection.service';
import { Product} from 'src/models/Product.model';
import { Geolocation } from '@capacitor/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {

  @Input('imageURL') imageURL;
  @Input('title') title;
  
  constructor() {
   }

  ngOnInit() {
  }



}
