import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Product } from "src/models/Product.model";
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from "rxjs";
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})

export class productioncollectionService {
    handleError: any;
 
    constructor(private httpClient: HttpClient, public storage: Storage) { }

    getAllBava() {
        return this.httpClient.get(environment.baseApiUrl + 'bava');
    }

    async createBava(bava: Product) {
        var userId = await this.storage.get('userId');
        return this.httpClient.post(environment.baseApiUrl + 'bava/create/' + userId, bava)
            .pipe(
                tap(data => console.log('create bava: ' + JSON.stringify(data))),
                catchError(this.handleError)
            ).toPromise()
    }

    deleteBava(id: string): Observable<{}> {
        return this.httpClient.delete<Product>(environment.baseApiUrl + 'bava/delete/?bavaId=' + id)
            .pipe(
                tap(() => console.log('deleted bava with id:  ' + id)),
                catchError(this.handleError)
            );
    }

    getProdotto(id: string): Observable<Product> {
        return this.httpClient.get<Product>(environment.baseApiUrl + 'oti721/read/tokenInfo/' + id).pipe(
            tap(_ => console.log(`fetched bava id=${id}`))
        );
    }

    updateProdotto(prodotto: { ForDatabase: any, ProductBlockchain: any }): Observable<Product> {
        return this.httpClient.put<Product>(environment.baseApiUrl + 'bava/update?bavaId=' + prodotto.ForDatabase._id, prodotto)
            .pipe(
                tap(() => console.log('updated bava: ' + prodotto.ForDatabase._id)),
                // Return the product on an update
                map(() => prodotto),
                catchError(this.handleError)
            );
    }

    getDocumentList(documentListId) {
        return this.httpClient.get(environment.baseApiUrl + 'document/document/documentListByIDs/' + documentListId);
    }

    getBavaStatus(name: string) {
        return this.httpClient.get(environment.baseApiUrl + 'bavastatuses/bavastatusesName/' + name);
    }

}