export enum StatusToPhase {
    Racc_TAG = 0,
    Fil_TAG = 1,
    Imbo_TAG = 2,
    Carico_TAG = 3,
    Analys_TAG = 4,
    ImboInFusto_TAG = 5,
    PreVendita_TAG = 6
}



export const PhaseToStatus = {
    0: 'Raccolta',
    1: 'Filtraggio',
    2: 'ImbottigliamentoInFusto',
    3: 'CaricoFustiInLab',
    4: 'AnalisiInLab',
    5: 'ImbottigliamentoInLab',
    6: 'PreVendita',
}

