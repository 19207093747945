import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductBlockchain } from 'src/models/ProductBlockchain.model';
import { ProductOnBlockchain } from 'src/models/ProductOnBlockchain.model';
import { Storage } from '@ionic/storage';
import { Product } from 'src/models/Product.model';
import { stringify } from 'querystring';



type ParseResult<T> =
  | { parsed: T; hasError: false; error?: undefined }
  | { parsed?: undefined; hasError: true; error?: unknown }

  const safeJsonParse = <T>(typeguard: (o: any) => o is T) => (text: string): ParseResult<T> => {
    try {
      const parsed = JSON.parse(text)
      return typeguard(parsed) ? { parsed, hasError: false } : { hasError: true }
    } catch (error) {
      return { hasError: true, error }
    }
  }

  function isProduct(o: any): o is Product {
    return "tokenid" in o && "container" in o && "drug" in o && "proprieta_storico" in o;
  }
  
  @Injectable({
    providedIn: 'root'
  })

export class SmartContractService {
  private readonly url: string = environment.baseApiUrl + 'oti721/'
  public address:string;
  constructor(
    private httpClient: HttpClient,
    private translateService: TranslateService,
    public storage: Storage
  ) { }

  async getNFTForLoggedUser() : Promise<string[]> {

    var nftList:string[] = [];
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt)
    const options = {
      headers: headers
    };

    let numb = await this.httpClient.get(this.url + 'read/balance/',options)
      .pipe(first())
      .toPromise()
      .then(num => num)
      .catch((e) => {return nftList});

    if(numb)
    {
    console.log("Numero:"+numb[0]);
    var num:number;

    for (num = 0;  num < numb[0]; num ++)
    {
      var tokenId = await this.httpClient.get(this.url + 'read/loggedUserTokenByIndex' +num,options)
      .pipe(first())
      .toPromise()
      .then(id => new String(id[0]))
      .catch((e) => {return ""});

      nftList.push(tokenId.toString());
    }
   }
    
    return nftList;

  }

  async setExtraInfo(tokenId:string,data: string) {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'application/json').set('responseType','string'); 
    const options = {
      headers: headers 
    };
    
    return this.httpClient.post<string>(this.url + 'maker/setTokenExtraInfo', JSON.stringify({ "tokenId": tokenId, "extraInfo": data }), options)
    .pipe(first())
    .toPromise()
    .then( async result => await this.test(result) )
    .catch((e) => {return false}); 
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async setInfo(tokenId:string,data: string) {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt)
    const options = {
      headers: headers
    };
    return await this.httpClient.post<string>(this.url + '/maker/setTokenInfo',  JSON.stringify({"tokenId":tokenId, "info":data}),options)
    .pipe(first())
    .toPromise()
    .then( async result => await this.test(result) )
    .catch((e) => {return false}); 
  }

  async getExtraInfo(tokenId: string) : Promise<string>
  {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'text/plain').
    set('responseType','string');
    const options = {
      headers: headers
      , responseType: 'text'
    };


    return await this.httpClient.get(this.url + 'read/extraInfo/' +tokenId,  {
      headers: headers
      , responseType: 'text'
    } )
    .pipe(first())
    .toPromise()
    .then((jsonData:string) => this.hex_to_ascii(jsonData))
    .catch((e) => {return ""}); 

  }

  async getInfo(tokenId: string) : Promise<string>
  {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'application/json');
    const options = {
      headers: headers
    };

    return await this.httpClient.get(this.url + 'read/info/' +tokenId,options)
    .pipe(first())
    .toPromise()
    .then(jsonData => this.hex_to_ascii(jsonData[0]))
    .catch((e) => {return ""}); 

  }

  async tokenIdByPayloadNFC(payload: string) : Promise<string>
  {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'application/json');
    const options = {
      headers: headers
    };
   // this.consoleLog(this.url + 'read/tokenIdByPayloadNFC/' +payload);
    return await this.httpClient.get(this.url + 'read/tokenIdByPayloadNFC/' +payload,options)
    .pipe(first())
    .toPromise()
    .then(jsonData => jsonData[0])
    .catch((e) => {return ""}); 

  }

  async tokenIdByPayload(payload: string) : Promise<string>
  {
    var jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'application/json');
    const options = {
      headers: headers
    };
    
    return await this.httpClient.get(this.url + 'read/tokenIdByPayload/' +payload,options)
    .pipe(first())
    .toPromise()
    .then(jsonData => jsonData[0])
    .catch((e) => {return ""}); 

  }
 
  async sendToken(tokenId:string,username: string, otiAddress: string, ruolo:string) 
  {
    let jwt = await this.storage.get("jwt");
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + jwt).set('content-type', 'application/json');
    const options = {
      headers: headers 
    };   
    let body =  JSON.stringify({"username":username, "otiAddress":otiAddress, "tokenId":tokenId});
    await this.httpClient.post<string>(this.url + 'maker/sendToDistributor',body,options)
    .pipe(first())
    .toPromise()
    .then(async result => await this.test(result))
    .catch((e) => {return false}); 
  }
 
  public consoleLog(data) {
    var logElement = document.getElementById('log');
    logElement.innerHTML += data + '\n';
  }

 async test(result:any)
 { 
    let dd= new Tx(result);
    this.address=dd.txid;
    let txtstr=await this.httpClient.get<string>(environment.baseApiUrl + 'otiWallet/tx/'+dd.txid).toPromise();
    let txinfo= new Confirmations(txtstr);
    while(txinfo.confirmations<1){
      await this.delay(1000);
       txtstr=await this.httpClient.get<string>(environment.baseApiUrl + 'otiWallet/tx/'+dd.txid).toPromise();
       txinfo= new Confirmations(txtstr);
    } 
    return true;
 }

  hex_to_ascii(str1:any)
 {
	var hex  = str1.toString();
	var str = '';
	for (var n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
 }

}

export class Tx {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  public txid:string;

 
}

export class Confirmations {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }
  public confirmations:number;

 
}
 